.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.leaflet-div-icon {
  background: transparent;
  border: none;
}

.vis-timeline {
  overflow-y: scroll;
}

.marker_label {
  border-radius: 0.5rem;
  white-space: nowrap;
  -webkit-hyphens: none;
  hyphens: none;
  font-size: 0.7rem;
  color: #fff;
  font-weight: 600;
  text-shadow: 0 0 0.15rem #000;
  position: fixed;
  line-height: 1.1em;
  background: rgba(0, 0, 0, 0.2);
  border: 0;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  -webkit-user-select: none;
  user-select: none;
}

.popup_list {
  max-height: 300px;
  overflow-y: auto;
}
